import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import HeroYellowFi from 'src/pages/pra-voce/financiamento-imobiliario/financiamento-eua/sections/HeroYellowFi/_index'
import YellowFiAgoraEInter from 'src/pages/pra-voce/financiamento-imobiliario/financiamento-eua/sections/YellowFiAgoraEInter/_index'
import PorqueFinanciar from 'src/pages/pra-voce/financiamento-imobiliario/financiamento-eua/sections/PorqueFinanciar/_index'
import QueroFinanciar from 'src/pages/pra-voce/financiamento-imobiliario/financiamento-eua/sections/QueroFinanciar/_index'

const FinanciamentoImobiliarioEUA = () => {
  const lang = 'en'

  return (
    <Layout pageContext={pageContext}>
      <HeroYellowFi lang={lang} />
      <YellowFiAgoraEInter lang={lang} />
      <PorqueFinanciar lang={lang} />
      <QueroFinanciar lang={lang} />
    </Layout>
  )
}

export default FinanciamentoImobiliarioEUA
